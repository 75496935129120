@tailwind base;
@tailwind components;
@tailwind utilities;

.submit-btn {
  @apply text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 w-full rounded-md py-3 px-8 text-center text-base font-semibold outline-none;
}

.add-new-btn {
  @apply text-green-700 hover:text-white border border-green-700 hover:bg-green-800 font-medium rounded-lg text-sm px-3 py-1 text-center;
}

.warning-btn {
  @apply text-yellow-400 hover:text-white border border-yellow-400 hover:bg-yellow-400 w-full rounded-md py-3 px-8 text-center text-base font-semibold outline-none;
}


.pill-btn-success {
  @apply text-white border border-green-700 bg-green-800 font-medium rounded-lg text-sm px-1 py-1 text-center;
}

.pill-btn-warning {
  @apply text-white border border-yellow-500 bg-yellow-500 font-medium rounded-lg text-sm px-1 py-1 text-center;
}

.loading-chats {
  position: fixed;
  z-index: 10;
  top: 50%;
  right: 40%;
}

.react-datepicker-wrapper,
.custom-date-wrapper {
  width: 100% !important;
}

.rmsc .dropdown-container:focus-within {
  border-color: green !important;
  box-shadow: 0 0 0 1px rgba(7, 128, 37, 0.2);

}